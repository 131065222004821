var _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['disableCookies']);
_paq.push(['enableLinkTracking']);
(function() {
    var u="//matomo.findhilde.de/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();